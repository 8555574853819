<template>
<v-row>
    <v-col  cols="12" :md="'6'" >
        <s-crud no-border formPermanent title="Definiciones" :config="this.config" @save="saveDef($event)" add edit remove sortable restore @rowSelected="rowSelected($event)" noConfimationSave searchInput>
            <template slot-scope="props">
                <v-container v-if="props.item != null" class="pb-0">
                    <v-row justify="center">
                        <v-col lg="12" md="12" class="s-col-form">
                            <s-text v-model="props.item.DefDescription" label="Definición" autofocus />
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:SecStatus="{ row }">
                <v-icon :color="row.SecStatus == 1 ? 'success' : 'default'"> mdi-checkbox-blank-circle</v-icon>
            </template>
        </s-crud>
    </v-col>
    <v-col cols="12" md="6">
        <s-definition-detail v-if="defID > 0" :defID="defID" />
    </v-col>
</v-row>
</template>

<script>
import _sDefinition from "@/services/General/DefinitionService";
import SDefinitionDetail from "./Definition/DefinitionDetail";
export default {
    components: {
        SDefinitionDetail,
    },
    data: () => ({
        options: [],
        defID: 0,
        config: {
            model: {
                DefDescription: "string",
                DefID: "ID",
                SecStatus: "status",
            },

            service: _sDefinition,
            headers: [{
                    width: "15%",
                    align: "end",
                    text: "ID",
                    value: "DefID",
                    sorteable: true,
                },
                {
                    text: "Definición",
                    value: "DefDescription",
                    sorteable: true,
                },
                {
                    width: "20%",
                    align: "center",
                    text: "Estado",
                    value: "SecStatus",
                    sorteable: true,
                },
            ],
        },
    }),

    methods: {
        rowSelected(items) {
            if (items.length > 0) this.defID = items[0].DefID;
            else this.defID = 0;
        },
        saveDef(item) {
            if (item.DefDescription.length == 0) {
                this.$fun.alert("Registre definición", "warning");
                return;
            }
            item.save();
        },
    },
    computed: {},
};
</script>
